import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'components/Layout'
import SectionWithColumnLayout from 'components/SectionWithColumnLayout'

const VaraTjanster = ({ data }) => (
  <Layout
    pageTitle='Våra tjänster'
    headerBackgroundImage={data.header.childImageSharp.gatsbyImageData}
    headerBackgroundPosition='center bottom'
  >
    <oma-grid-row contentAlignedWithGrid='true'>
      <SectionWithColumnLayout title='Våra tjänster'>
        <h2>Bokföring</h2>
        <p className='section__text'>
          Vi tar hand om den löpande bokföringen och skräddarsyr redovisningen
          utifrån kundens behov.
        </p>
        <h2>Fakturering</h2>
        <p className='section__text'>
          Vi kan erbjuda våra kunder en faktureringstjänst, där vi sköter
          faktureringen åt kunden. Det enda kunden behöver lämna in till oss är
          underlag för fakturorna.
        </p>
        <h2>Reskontrahantering</h2>
        <p className='section__text'>
          Genom reskontrahanteringen kan vi hjälpa kunden att få en överblick om
          kunderna betalar och hur mycket som är kvar att betala till
          leverantörerna.
        </p>
        <h2>Momsredovisning</h2>
        <p className='section__text'>
          Genom bokföringen kan vi ta fram underlag för momsredovisningen som
          skall lämnas in till Skatteverket.
        </p>
        <h2>Lönehantering</h2>
        <p className='section__text'>
          Utifrån kundens specifika behov kan vi anpassa lönehanteringen med
          hjälp av till exempel tidsredovisning, framställning av
          lönespecifikationer vid årets slut.
        </p>
        <h2>Kvitto och resa</h2>
        <p className='section__text'>
          Digital hantering av kvitton kopplade till kort eller utlägg.
          Reseräkningar digitalt med milersättning och traktamente direkt
          kopplat till lön och bokföring.
        </p>
        <h2>Bokslut & årsredovisningar</h2>
        <p className='section__text'>
          Vid årets slut upprättar vi kundens bokslut och årsredovisning för att
          sedan lämna dokumentationen till revisorn.
        </p>
        <h2>Deklarationer</h2>
        <p className='section__text'>
          Vi färdigställer företagets inkomstdeklaration och även den privata
          deklarationen när bokslutet är klart.
        </p>
        <h2>Bolagsbildning</h2>
        <p className='section__text'>
          När en ny kund vill starta ett eget företag eller köpa en redan bildat
          företag kan vi vara behjälplig vid bolagsbildningen. Vid
          nyetableringar av företag diskuterar vi med kunden vilka för- och
          nackdelar det finns med de olika företagsformerna, för att tillgodose
          kundens önskemål och behov.
        </p>
        <h2>Ekonomisk Rådgivning</h2>
        <p className='section__text'>
          När en kund behöver rådgivning i ekonomiska frågor som till exempel
          nyanställning eller investeringar i verksamheten finns vi till hands
          för att svara på kundens frågor och funderingar.
        </p>
        <h2>Betalningsservice</h2>
        <p className='section__text'>
          Vi hjälper kunden att betala leverantörsfakturor via banken och
          tjänsten kan skötas både från vårt egna kontor eller ute hos kunden,
          allt efter kundens önskemål.
        </p>
      </SectionWithColumnLayout>
    </oma-grid-row>
  </Layout>
)

export const query = graphql`
  {
    header: file(relativePath: { eq: "vara-tjanster.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 70, layout: FULL_WIDTH)
      }
    }
  }
`

export default VaraTjanster
